import { Pipe, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
    name: 'safeUrl',
    standalone: true
})
export class SafeUrlPipe {
    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) { }

    transform(url) {
        if (!!url) {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
        return "";
    }
}

@Pipe({
    name: 'safeResourceUrl',
    standalone: true
})
export class SafeResourceUrlPipe {
    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) { }

    transform(url) {
        if (!!url) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
        return "";
    }
}